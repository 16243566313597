p{
    text-align: left;
    max-width: 100%;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 1.2em;
}

p#larger{
    text-align: left;
    font-weight: bold;
    font-size: 1em;
    max-width: 100%;
}

img#educ{
    max-width: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.containerproject {
    display: flex;
    align-items: right;
    margin-left: 40px;
    margin-right: 40px;
    justify-content: center;
    align-items: center;
}

h1#educ{
    padding: 40px 0px 0px 0px;
    text-align: center;
    font-size: 2.6em;
}