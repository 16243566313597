nav {
    background: #000000;
    opacity: 0.7;
    padding: 10px;
    text-align: center;
    width: 100%;
    position: fixed ;
    top: 0px;
    min-height: 30px;
    transition: ease-in-out 0.3s;
    z-index: 20;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
nav a {
    color: white;
    text-decoration: none;
    margin: 0px 20px;
    font-size: 1.6em;
    opacity: 1;
}
nav a:hover {
    font-weight: bolder;
    color: rgba(5, 233, 165, 1);
    background: scroll;
}