section#contact {
    background: rgb(41, 41, 41);
    opacity: 0.94;
    color: #270942;
    min-height: 10vh;
    max-height: 100vh;
    width: 100%;
}

p#contact {
    font-size: 1em;
    color: white;
    text-align: left;
    margin-left: 40px;
    display: inline-block;
    margin-right: 40px;
}

a#contact {
    font-size: 0.8em;
    color: #0781FE;
    text-align: left;
    /* margin-left: 40px; */
}
a#git {
    font-size: 0.8em;
    color: white;
    text-align: left;
    /* margin-left: 40px; */
}

a#malt{
    font-size: 0.8em;
    color: #E74C3C;
    text-align: left;
}

h2#contact {
    margin-left: 40px;
    font-size: 1.6em;
    color: white;
}

img {
    margin-left: 40px;
}

.containerproj {
    display: flex;
    align-items: right;
    margin-left: 0px;
}