section#header {
    background: rgb(123,134,232);
    background: linear-gradient(164deg, rgba(3, 156, 128  ,1) 0%, rgba(5, 233, 165, 1) 52%, rgba(3, 156, 128  ,1)100%);
    color: #270942;
    min-height: 40vh;
    max-height: 100vh;
    width: 100%;
}

article#header {
    width: 100%;
    max-width: 1000px;
    padding: 5px;
    margin: 0px auto;
}

h2#header {
    text-align: center;
    padding: 40px 0px 0px 0px;
    font-size: 2em;
    /* color: #000000; */
    color: #E9FEF3;
}