p#about {
    font-size: 1.4em;
    color: #000000;
    text-align: left;
    margin-left: 40px;
    display: inline-block;
    min-width: 800px;
}

h1#about {
    font-size: 2em;
    margin-top: 80px;
    margin-left: 40px;
    color: #000000;
    text-align: left;
    display: flex;
    min-width: 500px;
}

img#about {
    text-align: right;
    display: inline-block;
    max-height: 250px;
    margin-left: 70px;
    display: inline-block;
    border-radius: 50%;
    opacity: 0.9;
}

.container {
    display: flex;
    align-items: right;
    margin-right: 40px;
    margin-left: 50px;
}

@media (prefers-reduced-motion: no-preference) {
    p#about {
      animation: text-spin cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s;
    }
}
@keyframes text-spin {
    from {
      transform: translate(1000px);
    }
    to {
      transform: translate(0px);
    }
}

@media (prefers-reduced-motion: no-preference) {
    img#about {
      animation: text-spin2  cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s;
    }
}
@keyframes text-spin2 {
    from {
      transform: translate(-1000px);
    }
    to {
      transform: translate(0px);
    }
}

@media (prefers-reduced-motion: no-preference) {
    h2#about {
      animation: text-spin3 cubic-bezier(0.165, 0.84, 0.44, 1) 2.5s;
    }
}
@keyframes text-spin3 {
    from {
      transform: translateY(-100px);
    }
    to {
      transform: translate(0px);
    }
}
