a#plus{
    margin-left: 85%;
}

a#project {
    font-size: 1em;
    color: #FED607;
    text-align: left;
}


a#ljk {
    font-size: 1em;
    color: #07C6FE;
    text-align: left;
}

a#timc {
    font-size: 1em;
    color: #1707FE;
    text-align: left;
}

h1#project{
    font-size: 1.8em;
    color: #000000;
    margin-left: 10px;
    font-style: italic;
}

h2#proj {
    text-align: center;
    padding: 40px 0px 0px 0px;
    font-size: 2.6em;
    text-align: center;
    font-weight: bold;
    text-shadow: 4px 4px 4px 4px white;
}

p#project{
    text-align: left;
    font-size: 1.2em;

}

p#title{
    text-align: left;
    font-weight: bold;
    min-width: 100%;
    font-size: 1.2em;
    margin-left: 0px;
    margin-top: 0%;
}

img{
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 10px;
    max-height: 100px;
    align-items: center;
}

video{
    margin-left: 25%;
    margin-right: 10px;
    margin-left: 20px;
    margin-top: 10px;
    max-height: 100px;
    align-items: center;
}

.containerproject {
    display: flex;
    align-items: top;
    margin-left: 0px;
}

li{
    text-align: left;
}

div#box{
    box-shadow: rgba(1, 71, 57, 0.24) 0px 3px 8px;
    width: 95%;
    /* height: 400px; */
    height: fit-content;
    display: flex;
    align-items: top;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
}

div#box:hover{
    box-shadow: rgba(1, 71, 57, 0.24) 2px 6px 12px;

}


.one {
    background-image: url("")
    /* background-image: url("/Users/isee/PERSO/mon-app-portfolio/src/image/port.png");
    background-size: 100%; */
    /* opacity: 0.8; */
  }

.two {
    /* background-image: url(""); */
    /* background-image: url("/Users/isee/PERSO/mon-app-portfolio/src/image/34RS.png"); */
    background-size: 100%;
    /* opacity: 0.6; */
  }

.three {
    /* background-image: url(""); */
    /* background-image: url("/Users/isee/PERSO/mon-app-portfolio/src/image/inpaint.png"); */
    background-size: 100%;
  }

.four {
    /* background-image: url("/Users/isee/PERSO/mon-app-portfolio/src/image/scene4.png"); */
    background-size: 100%;
  }

.five {
    background-image: url("");
  }

/* div :hover{
    opacity:1;
} */

